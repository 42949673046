import React, { Component } from "react";

const Footer = () => (
  <>
    <footer className='l-b'>
      <div className='container'>
        <div className='row'>
          <div className='c-3'>
            <img
              src='/images/footer-logo.svg'
              alt='Yatilabs logo'
              className='logo'
            />
          </div>
        </div>
        <div className='row'>
          <div className='c-3'></div>
          <div className='c-9'>
            <div className='locations'>
              <div className='outer'>
                <h3>India</h3>
                <p>
                  SCF 46, First Floor
                  <br />
                  Sector 4, 134112
                  <br />
                  Panchkula, India
                </p>
              </div>
              <div className='outer'>
                <h3>U.S.A</h3>
                <p>
                  4990 Lighthouse Cir
                  <br />
                  Apt E, 33063
                  <br />
                  Coconut Creek, FL, USA
                </p>
              </div>
              <div className='outer'>
                <h3>Dubai</h3>
                <p>
                  Office No. 201,
                  <br />
                  Saeed Al Tayer Building
                  <br />
                  Al Rafaa Area, Dubai
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='c-3'></div>
          <div className='c-9'>
            <div className='copyright'>
              <ul>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li>
                  <a href='/work'>Work</a>
                </li>
                <li>
                  <a href='/contact'>Contact</a>
                </li>
                <li>
                  <a href='/blog'>Insights</a>
                </li>
              </ul>
              <p>
                © {new Date().getFullYear()} Yatilabs.com. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
);

export default Footer;
