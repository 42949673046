import React, { Component } from "react";

import Header from "./../sections/Header";
import Footer from "./../sections/Footer";
import Menu from "./../sections/Menu";
import Talk from "../sections/Talk";
import { Helmet } from "react-helmet";

class Contact extends Component {
  componentWillMount() {
    document.body.classList.add("contact-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("contact-page");
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Contact Yati Labs - Get a proposal for your Mobile App Design and
            Development Project.
          </title>
          <meta
            name='description'
            content='We’ve worked with clients of all sizes, from enterprise brands to funded startups. Let’s talk about your project and how we can help provide value.'
          />
          <meta
            property='og:description'
            content='We’ve worked with clients of all sizes, from enterprise brands to funded startups. Let’s talk about your project and how we can help provide value.'
          />
          <meta property='og:type' content='website' />
          <meta
            property='og:title'
            content='Contact Yati Labs - Get a proposal for your Mobile App Design and Development Project.'
          />
        </Helmet>
        <Menu />
        <Header />

        <section class='page-heading'>
          <div class='bg'></div>
          <h1>Contact</h1>
        </section>

        <section class='o-w l-b empty-mt' style={{ marginBottom: "140px" }}>
          <div class='container'>
            <div class='ct'>
              <h3 class='heading'>Get to know us.</h3>
              <h2>
                We’ve worked with clients of all sizes, from enterprise brands
                to funded startups. Let’s talk about your project and how we can
                help provide value.
              </h2>
            </div>
          </div>
        </section>

        <Talk />
        <Footer />
      </>
    );
  }
}

export default Contact;
