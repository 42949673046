import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { config } from "../../config/Global";
export default class Talk extends Component {
  constructor(props) {
    super(props);
    if (this.props.link) {
      this.state = { link: "true" };
    } else {
      this.state = { link: "false" };
    }
    this.validator = new SimpleReactValidator({
      messages: {
        default: "*Required",
      },
    });
    this.state = {
      name: "",
      email: "",
      company: "",
      phone: "",
      about: "",
      captcha: "",
      buttonTxt: "",
    };
    this.state = {
      currentLocation: window.location.pathname,
    };

    // console.log(this.state.currentLocation);
  }
  componentWillMount() {
    this.setState({
      buttonTxt: "Submit",
      firstNo: Math.floor(Math.random() * 10),
      secondNo: Math.floor(Math.random() * 10),
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state);
    let ans = this.state.firstNo + this.state.secondNo;
    console.log(ans);
    if (this.validator.allValid()) {
      console.log("hi");
      console.log(this.state.captcha);
      if (this.state.captcha == ans) {
        this.setState({ buttonTxt: "Please Wait..." });
        fetch(config.contactFrom, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(this.state),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status == 200) {
              this.setState({ buttonTxt: "Redirecting..." });
              window.location = "/thankyou";
            } else {
              alert("something went wrong.");
            }
            // setTimeout(function () {
          });
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  render() {
    return (
      <>
        <section className='le-t d-b'>
          <div className='container'>
            <div className='row'>
              <div className='c-6' style={{ "place-self": "baseline" }}>
                <h3
                  style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                  Let’s Talk
                </h3>

                {this.state.currentLocation == "/contact" ? (
                  <a className='emaillink' href='mailto:hey@yatilabs.com'>
                    hey@yatilabs.com
                  </a>
                ) : (
                  <p>
                    We’ve worked with clients of all sizes, from enterprise
                    brands to funded startups. Let’s talk about your project and
                    how we can help provide value.
                  </p>
                )}
              </div>
              <div className='c-6'>
                <div className='form'>
                  <form onSubmit={this.handleSubmit}>
                    <div>
                      <input
                        type='text'
                        placeholder='Name'
                        name='name'
                        onChange={this.handleChange}
                        autoComplete='off'
                      />
                      {this.validator.message(
                        "name",
                        this.state.name,
                        "required|alpha_space"
                      )}
                    </div>
                    <div>
                      <input
                        type='text'
                        placeholder='Email'
                        name='email'
                        onChange={this.handleChange}
                        autoComplete='off'
                      />
                      {this.validator.message(
                        "email",
                        this.state.email,
                        "required|email"
                      )}
                    </div>
                    <div>
                      <input
                        type='text'
                        placeholder='Company'
                        name='company'
                        onChange={this.handleChange}
                        autoComplete='off'
                      />
                      {this.validator.message(
                        "company",
                        this.state.company,
                        "required"
                      )}
                    </div>
                    <div>
                      <input
                        type='text'
                        placeholder='Phone'
                        name='phone'
                        onChange={this.handleChange}
                        autoComplete='off'
                      />
                      {this.validator.message(
                        "phone",
                        this.state.phone,
                        "required"
                      )}
                    </div>
                    <div style={{ gridColumn: "1/3" }}>
                      <textarea
                        name='message'
                        rows='2'
                        placeholder='Tell us about your project.'
                        onChange={this.handleChange}
                        autoComplete='off'
                      ></textarea>
                      {this.validator.message(
                        "message",
                        this.state.message,
                        "required"
                      )}
                    </div>

                    <div style={{ gridColumn: "1/3" }}>
                      <div className='nda'>
                        <div>
                          <input
                            type='checkbox'
                            placeholder='Enter answer'
                            name='nda'
                            onChange={this.handleChange}
                            autoComplete='off'
                            className='nda'
                          />
                        </div>
                        Send me a copy of nda
                      </div>
                    </div>
                    <div style={{ gridColumn: "1/3" }}>
                      <div className='captcha'>
                        {this.state.firstNo} + {this.state.secondNo} =
                        <div>
                          <input
                            type='text'
                            placeholder='Enter answer'
                            name='captcha'
                            onChange={this.handleChange}
                            autoComplete='off'
                            className='captcha'
                          />
                          {this.validator.message(
                            "captcha",
                            this.state.captcha,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                    <button type='submit' name='Submit' value='Submit'>
                      {this.state.buttonTxt === ""
                        ? "Submit"
                        : this.state.buttonTxt}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
