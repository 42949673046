import React, { Component } from "react";

import Header from "./../sections/Header";
import Footer from "./../sections/Footer";
// import Splash from "./../sections/Splash";
import Menu from "./../sections/Menu";
import Insights from "../sections/Insights";
// import BlogFrame from "../sections/BlogFrame";
import DocumentTitle from "react-document-title";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.slug = this.props.match.params.slug;
    this.state = {
      post: "",
    };
  }

  componentDidMount() {
    if (this.slug) {
      fetch(
        "https://www.yatilabs.com/wp/wp-json/api/v1/postsWithSlug?slug=" +
          this.slug
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({ post: data[0] });
          // setTimeout(function () {
        });
    } else {
      fetch("https://www.yatilabs.com/wp/wp-json/api/v1/latestPost")
        .then((response) => response.json())
        .then((data) => {
          this.setState({ post: data[0] });
          // setTimeout(function () {
        });
    }
  }

  /* shouldComponentUpdate(nextProps, nextState) {
    if (this.state.post !== nextState.post) {
      return true;
    }
    return false;
  } */

  render() {
    let post = this.state.post;
    console.log(this.state.post);
    let pageTitle = "Yatilabs | " + post.title;
    return (
      <>
        <DocumentTitle title={pageTitle}></DocumentTitle>
        <Header />

        <section className='page-heading'>
          <div className='bg'></div>
          <h1>Insights </h1>
        </section>

        <Menu />
        <section className='b-iframe l-b'>
          <div className='container'>
            <div className='row'>
              <div
                className='c-12'
                style={{ maxWidth: "767px", margin: "auto" }}
              >
                {/* <BlogFrame /> */}
                {/* {post.content} */}
                <title>{post.title}</title>

                <div dangerouslySetInnerHTML={{ __html: post.content }} />
              </div>
            </div>
          </div>
        </section>

        <Insights posts={-1} hide={this.slug} />
        {/* <Talk /> */}
        <Footer />
      </>
    );
  }
}

export default Blog;
