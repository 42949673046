import React, { Component } from "react";

import Header from "./../sections/Header";
import Footer from "./../sections/Footer";
import Menu from "./../sections/Menu";

class Thankyou extends Component {
  componentWillMount() {
    document.body.classList.add("contact-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("contact-page");
  }
  render() {
    return (
      <>
        <Menu />
        <Header />

        <section className='thankyou l-b'>
          <div className='container'>
            <div className='row'>
              <div className='c-6'>
                <div className='outer'>
                  <h3>Thank You.</h3>
                  <p>
                    We have received your message. A member of our business
                    development team will contact you shortly.
                  </p>
                  <a href='/'>Back To Home</a>
                </div>
              </div>
              <div className='c-6'>
                <video autoplay loop muted webkit-playsinline>
                  <source src='images/thankyou.mp4' type='video/mp4' />
                </video>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

export default Thankyou;
