import React, { Component, Suspense } from "react";

import Header from "./../sections/Header";
import Footer from "./../sections/Footer";
import Splash from "./../sections/Splash";
import Menu from "./../sections/Menu";
import Insights from "../sections/Insights";
import Talk from "../sections/Talk";
import { Helmet } from "react-helmet";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splash: false,
    };
  }
  componentDidMount() {
    const splash = window.sessionStorage.getItem("splash");
    if (!splash) {
      window.sessionStorage.setItem("splash", true);
    } else {
      this.setState({
        splash: true,
      });
    }
    console.log(window.sessionStorage.getItem("splash"));
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Yati Labs | A full-service agency that specializes in building
            mobile apps, websites, enterprise softwares.
          </title>
          <meta
            name='description'
            content='A full-service agency that specializes in building mobile apps, websites, enterprise softwares.'
          />
          <meta
            property='og:description'
            content='A full-service agency that specializes in building mobile apps, websites, enterprise softwares.'
          />
          <meta property='og:type' content='website' />
          <meta
            property='og:title'
            content='Yati Labs - Full-Stack Mobile (iOS, Android) & Web App Design & Development Agency'
          />
        </Helmet>
        <Header />
        {this.state.splash ? (
          <section className='page-heading'>
            <div className='bg'></div>
            <h1>Home</h1>
          </section>
        ) : (
          <Splash />
        )}

        <Menu />

        <section className='fr-s l-b'>
          <div className='container'>
            <h1>
              <span>“</span>Not all your dreams may come true but certainly your
              IDEAS can.“
            </h1>
            <h5>- Yati Labs</h5>
            <h3>
              We are makers of digital products and experiences for startups,
              enterprises and forward looking brands.
            </h3>
            <div className='mob-cta'>
              <a href='/contact'>Let’s Talk</a>
            </div>
            <div className='mob'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='381.002'
                height='697'
                viewBox='0 0 381.002 697'
              >
                <defs>
                  <mask id='mobileMask'>
                    <rect
                      x='-5'
                      y='-90'
                      width='370'
                      height='110%'
                      fill='white'
                    ></rect>
                  </mask>
                </defs>
                <g
                  id='mobile'
                  transform='translate(-33.738 78.14)'
                  mask='url(#mobileMask)'
                >
                  <g id='body'>
                    <path
                      id='Path_66'
                      data-name='Path 66'
                      className='cls-1'
                      d='M414.74-23.14v587a55.007,55.007,0,0,1-55,55h-261a55,55,0,0,1-55-55v-587a54.994,54.994,0,0,1,55-55h53v16a20.781,20.781,0,0,0,1.65,8.17,20.987,20.987,0,0,0,4.5,6.68,20.945,20.945,0,0,0,6.67,4.5,20.973,20.973,0,0,0,8.18,1.65h136a20.96,20.96,0,0,0,8.17-1.65,20.987,20.987,0,0,0,6.68-4.5,20.987,20.987,0,0,0,4.5-6.68,20.96,20.96,0,0,0,1.65-8.17v-16h30A55,55,0,0,1,414.74-23.14Z'
                    />
                    <path
                      id='Path_67'
                      data-name='Path 67'
                      className='cls-2'
                      d='M413.74-23.14v587a54,54,0,0,1-54,54h-261a54,54,0,0,1-54-54v-587a53.992,53.992,0,0,1,54-54h53v15a20.781,20.781,0,0,0,1.65,8.17,20.987,20.987,0,0,0,4.5,6.68,20.945,20.945,0,0,0,6.67,4.5,20.973,20.973,0,0,0,8.18,1.65h136a20.96,20.96,0,0,0,8.17-1.65,20.987,20.987,0,0,0,6.68-4.5,20.987,20.987,0,0,0,4.5-6.68,20.96,20.96,0,0,0,1.65-8.17v-15h30A54,54,0,0,1,413.74-23.14Z'
                    />
                  </g>
                  <rect
                    id='down-b'
                    width='3'
                    height='56'
                    transform='translate(33.738 42.858)'
                  ></rect>
                  <rect
                    id='up-b'
                    width='3'
                    height='56'
                    transform='translate(33.738 108.858)'
                  ></rect>
                </g>
              </svg>
            </div>
          </div>
        </section>

        <section className='w-we-d d-b'>
          <div className='container'>
            <h3 className='heading'>What we do.</h3>
            <h2>
              Your ideas combined with our perfect services suite will result in
              the seamlessly integrated experience of your product that your
              users will love.
            </h2>
            <div className='services'>
              <div className='row'>
                <div className='c-2'></div>
                <div className='c-10'>
                  <div className='row'>
                    <div className='c-6'>
                      <div className='outer'>
                        <h3>App Development</h3>
                        <h4>
                          iOS · Android · Xamarin · Flutter · React Native
                        </h4>
                        <p>
                          Planning, testing and developing of the mobile
                          application by the expert team of developers targeting
                          innovation and creativity which gratifies the users.
                        </p>
                      </div>
                      <div className='outer'>
                        <h3>Product Design</h3>
                        <h4>
                          Visual & UX Design · User Testing · Content Strategy
                        </h4>
                        <p>
                          We keep in mind the vision, strategy and process to
                          design a product which results in a beautiful
                          amalgamation of the needs of the clients, technologies
                          and growth.
                        </p>
                      </div>
                      <div className='outer'>
                        <h3>Research & Insights</h3>
                        <h4>
                          User Research · Feature Prioritization · Analytics
                          Audits
                        </h4>
                        <p>
                          Quality, not quantity is a yardstick of our company’s
                          success. We delve into rich qualitative research and
                          fulfil the needs of the users for best interactivity
                          and experiences.
                        </p>
                      </div>
                    </div>
                    <div className='c-6'>
                      <div className='outer'>
                        <h3>Web Development</h3>
                        <h4>Website · SEO · CI/CD · REST APIs </h4>
                        <p>
                          Web is ever evolving, flexible & ubiquitous. We ensure
                          that web applications surpass users expectations in
                          the mobile age, including accessibility and responsive
                          design.
                        </p>
                      </div>
                      <div className='outer'>
                        <h3>Strategy & Innovation</h3>
                        <h4>Product Strategy · Concept/Idea Validation</h4>
                        <p>
                          We foster a shared understanding among the clients,
                          developers, brand and vision in order to get the
                          bestest version as per the demand in the marketplace.
                        </p>
                      </div>
                      <div className='outer'>
                        <h3>Growth Marketing</h3>
                        <h4>
                          Personalization · Marketing Automation · Performance
                        </h4>
                        <p>
                          Data-driven strategies maximize lifecycle value. We’ll
                          help you define metrics for acquisition, retention,
                          and conversion, then align your technology and
                          marketing execution.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='o-w l-b'>
          <div className='container'>
            <div className='ct'>
              <h3 className='heading'>Our Work.</h3>
              <h2>
                We work closely with our clients to define, design and develop
                transformative user experiences. We believe in what we do and
                it’s the quality, diligence and sincerity that we put into
                making a product that distinguishes us from the crowd.
              </h2>
            </div>
            <div className='projects one'>
              <div className='row'>
                <div className='c-6'>
                  <div className='p-n'>
                    <h3>Synkers</h3>
                    <h4>Qualified Tutors</h4>
                    <p>Find an expert private tutor for all your needs.</p>
                    <div className='btns'>
                      <a
                        href='https://play.google.com/store/apps/details?id=com.synkers.synkers&hl=en_IN'
                        target='_blank'
                      >
                        <img src='images/android.svg' alt='android yatilabs' />
                        <span>Android</span>
                      </a>
                      <a
                        href='https://apps.apple.com/us/app/synkers-qualified-tutors/id1100731633'
                        target='blank'
                      >
                        <img src='images/apple.svg' alt='iOS Yatilabs' />
                        <span>iOS</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='c-6'>
                  <div className='p-i'>
                    <img src='images/synkers1.png' alt='Synkers Yatilabs' />
                    <img src='images/synkers2.png' alt='Synkers Yatilabs' />
                  </div>
                </div>
              </div>
            </div>
            <div className='projects two'>
              <div className='row'>
                <div className='c-6'>
                  <div className='p-n'>
                    <h3>Careem Now</h3>
                    <h4>Your New Delivery App</h4>
                    <p>
                      Delighting you with your favourite meals, delivered
                      directly to anywhere you are.
                    </p>
                    <div className='btns'>
                      <a
                        href='https://play.google.com/store/apps/details?id=com.careem.acma'
                        target='_blank'
                      >
                        <img src='images/android.svg' alt='android yatilabs' />
                        <span>Android</span>
                      </a>
                      <a
                        href='https://apps.apple.com/in/app/careem-rides-food-delivery/id592978487'
                        target='_blank'
                      >
                        <img src='images/apple.svg' alt='iOS Yatilabs' />
                        <span>iOS</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='c-6'>
                  <div className='p-i'>
                    <img src='images/careem1.png' alt='Careem Now Yatilabs' />
                    <img src='images/careem2.png' alt='Careem Now Yatilabs' />
                  </div>
                </div>
              </div>
            </div>
            <div className='projects three'>
              <div className='row'>
                <div className='c-6'>
                  <div className='p-n'>
                    <h3>Lybrate</h3>
                    <h4>Consult a Doctor</h4>
                    <p>
                      India’s #1 Medical platform connecting over 10 million
                      users to over 150K top doctors and pathology labs.
                    </p>
                    <div className='btns'>
                      <a
                        href='https://play.google.com/store/apps/details?id=com.lybrate'
                        target='_blank'
                      >
                        <img src='images/android.svg' alt='android yatilabs' />
                        <span>Android</span>
                      </a>
                      <a
                        href='https://apps.apple.com/in/app/lybrate-consult-a-doctor/id960716567'
                        target='_blank'
                      >
                        <img src='images/apple.svg' alt='iOS Yatilabs' />
                        <span>iOS</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='c-6'>
                  <div className='p-i'>
                    <img src='images/lybrate1.png' alt='Lybrate Yatilabs' />
                    <img src='images/lybrate2.png' alt='Lybrate Yatilabs' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='w-y-l d-b'>
          <div className='container'>
            <div className='row'>
              <div className='c-6'>
                <h3>We Are Yati Labs.</h3>
                <p>
                  Creating customized digital experiences for your ideas. Yati
                  Labs is a true development partner whose mission is to design,
                  build & innovate for your business.
                  <br />
                  <br />
                  We help kickstart startups. We fuel enterprise software. We
                  expand horizons. We are simply passionate about developing
                  truly memorable mobile apps and websites.
                  <br />
                  <br />
                  {/* <span>
                    We believe “not all our dreams may come true but certainly
                    our ideas can.” 
                  </span> */}
                </p>
              </div>
              <div className='c-6'>
                <div className='explore'>
                  <a href='work'>
                    Explore
                    <br />
                    Our Work <img src='images/arrow.svg' alt='arrow' />
                  </a>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='c-12'>
                <div className='clients l-b'>
                  <img src='images/clients/1.png' alt='Yatilabs' />
                  <img src='images/clients/2.png' alt='Yatilabs' />
                  <img src='images/clients/3.jpg' alt='Yatilabs' />
                  <img src='images/clients/4.png' alt='Yatilabs' />
                  <img src='images/clients/5.png' alt='Yatilabs' />
                  <img src='images/clients/6.png' alt='Yatilabs' />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='w-c-s l-b'>
          <div className='container'>
            <h3 className='heading'>What our clients say.</h3>
            <div className='testimonials'>
              <div className='outer-slider cstm-Slider'>
                <div className='outer'>
                  <div className='image'>
                    <img src='images/josh.jpeg' alt='Yatilabs' />
                  </div>
                  <p>
                    I've had several projects with the team at Yati Labs over
                    the past few years. In that time they've been extremely
                    professional, punctual, and have shown a deep knowledge of
                    software development. They have a gift in paying attention
                    to detail and bringing an idea on my mind to life! They are
                    my "go to" for any software projects we have at Fine Dine
                    App.
                  </p>
                  <h4>Joshua Liggins, FineDine</h4>
                </div>
                <div className='outer'>
                  <div className='image'>
                    <img src='images/jamesb.png' alt='Yatilabs' />
                  </div>
                  <p>
                    They are great to work with. Been four years working with
                    them, here are some key takeaways : only commit what they
                    can deliver, cost efficient, 24/7 support, their design team
                    is the best.
                  </p>
                  <h4>Jeff B, Healow</h4>
                </div>
                <div className='outer'>
                  <div className='image'>
                    <img src='images/robertp.png' alt='Yatilabs' />
                  </div>
                  <p>
                    Professionals! Intrigued by the length they go to get the
                    work done. Already one app delivered for my company and 2
                    more in pipeline...Highly impressed by their app developers!
                  </p>
                  <h4>Robert Page, Pocketsmith</h4>
                </div>
                <div className='outer'>
                  <div className='image'>
                    <img src='images/dustinb.png' alt='Yatilabs' />
                  </div>
                  <p>
                    They are a pleasure to deal with. They will take the time to
                    understand what you want, provide real guidance and insight,
                    and work with you to get the final product you want. I would
                    definitely work with them again on the next project.
                  </p>
                  <h4>Dustin Baker, txtReminders</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Insights posts={3} />

        <Talk />
        <Footer />
      </>
    );
  }
}
export default Index;
