import React, { Component } from "react";

import Header from "./../sections/Header";
import Footer from "./../sections/Footer";
import Menu from "./../sections/Menu";

class Notfound extends Component {
  componentWillMount() {
    document.body.classList.add("contact-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("contact-page");
  }
  render() {
    return (
      <>
        <Menu />
        <Header />

        <section className="thankyou l-b">
          <div className="container">
            <div className="row">
              <div className="c-12">
                <div className="outer">
                  <h3>404</h3>
                  <h4>The page you were looking for does not exist!</h4>
                  <a href="/">Back To Home</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

export default Notfound;
