import React, { Component } from "react";
import { gsap, TimelineMax } from "gsap/all";

gsap.registerPlugin(gsap, TimelineMax);

export default class Menu extends Component {
  constructor(props) {
    // console.log(window.location.pathname);
    super(props);
    this.state = {
      currentLocation: window.location.pathname,
      latestPost: "",
    };
    this.sideMenuOpen = null;
    this.sideMenuClose = null;
    this.sectionMbg = null;
    this.menuSpanOne = null;
    this.menuSpanTwo = null;
    this.header = null;
    this.menuCloseA = null;
    this.leftSide = null;
    this.rightSide = null;
    this.columnFour = null;
    this.columnSixA1 = null;
    this.columnSixA2 = null;
    this.columnSixA3 = null;
    this.columnSixA4 = null;
  }
  componentDidMount() {
    // console.log("cim");
    // create logo tween
    this.sideMenuOpen = new TimelineMax({ paused: true })
      .to(this.sectionMbg, 0, { autoAlpha: 1 })
      .to(this.menuSpanOne, 0.1, { width: 0 })
      .to(this.menuSpanTwo, 0, { autoAlpha: 0 })
      .to(this.header, 0.2, { autoAlpha: 0 })
      .to(this.menuCloseA, 0.2, { autoAlpha: 1 }, "+=.3")
      .to(this.header, 0, { backgroundColor: "transparent" })
      .to(this.leftSide, { borderLeftWidth: "100vw" }, "-=.4")
      .to(this.rightSide, 0.4, { borderRightWidth: "100vw" }, "-=.5")
      // .to('body', 0, {height: '100vh')
      .to(this.columnFour, 0.2, { opacity: 1, y: -20 }, "+=.2")
      .to(this.columnSixA1, 0.2, { opacity: 1, y: -20 }, "-=.3")
      .to(this.columnSixA2, 0.2, { opacity: 1, y: -20 })
      .to(this.columnSixA3, 0.2, { opacity: 1, y: -20 })
      .to(this.columnSixA4, 0.2, { opacity: 1, y: -20 })
      .to(this.header, 0.3, { autoAlpha: 1 }, "-=.7");
    this.sideMenuClose = new TimelineMax({ paused: true })
      .to(this.header, 0.2, { autoAlpha: 0 })
      .to(this.menuCloseA, 0, { autoAlpha: 0 })
      .to(this.columnSixA1, 0.2, { opacity: 0, y: 0 }, "-=.3")
      .to(this.columnSixA2, 0.2, { opacity: 0, y: 0 })
      .to(this.columnSixA3, 0.2, { opacity: 0, y: 0 })
      .to(this.columnSixA4, 0.2, { opacity: 0, y: 0 })
      .to(this.columnFour, 0.2, { opacity: 0, y: -0 })
      .to(this.leftSide, 0.4, { borderLeftWidth: "0vw" }, "+=.2")
      .to(this.rightSide, 0.4, { borderRightWidth: "0vw" }, "-=.4")
      .to(this.header, 0, { backgroundColor: "white" })
      .to(this.header, 0.2, { autoAlpha: 1 })
      .to(this.menuSpanOne, 0, { autoAlpha: 1 })
      .to(this.menuSpanTwo, 0, { autoAlpha: 1 })
      .to(this.menuSpanOne, 0.1, { width: 35 })
      .to(this.menuSpanTwo, 0.1, { width: 35 }, "-=.1")
      .to(this.sectionMbg, 0, { autoAlpha: 0 });

    fetch(
      "https://www.yatilabs.com/blog/wp-json/api/v1/latestPost?posts=" +
        this.noofposts
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ latestPost: data[0] });
      });
  }

  handleMenuOpen() {
    this.sideMenuOpen.play();
    this.sideMenuOpen.restart();
  }
  handleMenuClose() {
    this.sideMenuClose.play();
    this.sideMenuClose.restart();
  }

  render() {
    console.log("latestPost", this.state.latestPost);
    return (
      <>
        <section
          className='m-bg d-b'
          ref={(section) => (this.sectionMbg = section)}
        >
          <div className='left' ref={(div) => (this.leftSide = div)}></div>
          <div className='right' ref={(div) => (this.rightSide = div)}></div>
          <div className='container'>
            <div className='row'>
              <div className='c-4' ref={(div) => (this.columnFour = div)}>
                <h4>Get In Touch.</h4>
                <a href='#!'>hey@yatilabs.com</a>
              </div>
              <div className='c-2'></div>
              <div className='c-6'>
                <ul>
                  <li>
                    <a
                      href='/'
                      className={
                        this.state.currentLocation === "/" ? "active" : ""
                      }
                      ref={(a) => (this.columnSixA1 = a)}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href='/work'
                      className={
                        this.state.currentLocation === "/work" ? "active" : ""
                      }
                      ref={(a) => (this.columnSixA2 = a)}
                    >
                      Work
                    </a>
                  </li>
                  <li>
                    <a
                      href='/contact'
                      className={
                        this.state.currentLocation === "/contact"
                          ? "active"
                          : ""
                      }
                      ref={(a) => (this.columnSixA3 = a)}
                    >
                      Contact
                    </a>
                  </li>
                  <li>
                    <a
                      href={this.state.latestPost.link}
                      className={
                        this.state.currentLocation === "/blog" ? "active" : ""
                      }
                      ref={(a) => (this.columnSixA4 = a)}
                    >
                      Insights
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <header
          className='header not-sticky'
          ref={(header) => (this.header = header)}
        >
          <div className='logo'>
            <a href='/'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='42'
                height='42'
                viewBox='0 0 160 160'
              >
                <path
                  fill='#fff'
                  d='M135.13,127.06H151.2a0,0,0,0,1,0,0v16.07a0,0,0,0,1,0,0h0a16.07,16.07,0,0,1-16.07-16.07v0A0,0,0,0,1,135.13,127.06Z'
                />
                <path
                  fill='#fff'
                  d='M102.68,68.4l36-68.4h20.66L111.78,85.65v57.48h0a18.19,18.19,0,0,1-18.19-18.2V85.65L46,0H66.86Z'
                />
                <polygon points='79.42 143.13 0 143.13 0 0 30.49 0 30.49 15.9 16 15.9 16 127.23 79.42 127.23 79.42 143.13' />
              </svg>
            </a>
          </div>
          <div className='l-t-b'>
            <a href='/contact'>Let's Talk</a>
          </div>
          <div className='menu'>
            <a href='#!' onClick={() => this.handleMenuOpen()}>
              <span ref={(span) => (this.menuSpanOne = span)}></span>
              <span ref={(span) => (this.menuSpanTwo = span)}></span>
            </a>
            <div className='menu-c'>
              <a
                href='javascript:void(0)'
                onClick={() => this.handleMenuClose()}
                ref={(a) => (this.menuCloseA = a)}
              >
                x
              </a>
            </div>
          </div>
        </header>
      </>
    );
  }
}
