import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Index from "./views/pages/Index";
import Work from "./views/pages/Work";
import Contact from "./views/pages/Contact";
import Blog from "./views/pages/Blog";
import Thankyou from "./views/pages/Thankyou";
import Notfound from "./views/pages/Notfound";

ReactDOM.hydrate(
  // <BrowserRouter basename={"/yatilabs"}>
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path='/' exact component={Index} />
        <Route path='/index' render={(props) => <Index {...props} />} />
        <Route path='/work' render={(props) => <Work {...props} />} />
        <Route path='/contact' render={(props) => <Contact {...props} />} />
        {/* <Route path="/blog/:slug?" render={(props) => <Blog {...props} />} /> */}
        <Route path='/thankyou' render={(props) => <Thankyou {...props} />} />
        <Route component={Notfound} />
        {/* <Redirect to="/index" />
        <Redirect exact from="/" to="/index" /> */}
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
