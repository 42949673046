import React, { Component } from "react";
import { gsap, TimelineMax } from "gsap/all";

gsap.registerPlugin(TimelineMax);

export default class Insights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
    this.insights = null;
    this.instOuter = [];
    this.sectionHeading = null;
    this.noofposts = this.props.posts;
    this.hide = this.props.hide;
    console.log(this.hide);
  }
  componentDidMount() {
    // Simple GET request using fetch

    fetch(
      "https://www.yatilabs.com/blog/wp-json/api/v1/homePosts?posts=" +
        this.noofposts
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        let hide = this.hide;
        let newData = "";
        if (!hide) {
          console.log("if");
          newData = data;
        } else {
          console.log("else");
          newData = data.filter(function (item) {
            return item["slug"] != hide;
          });
        }
        console.log(newData);
        this.setState({ posts: newData });
        // setTimeout(function () {
        this.insights = new TimelineMax().to(this.sectionHeading, 1, {
          opacity: 1,
        });
        {
          this.state.posts.map((post, index) =>
            this.insights.to(
              this.instOuter[index],
              0.3,
              { opacity: 1, y: 0 },
              "-=.5"
            )
          );
        }
      });
  }
  render() {
    return (
      <>
        <section className='inst l-b'>
          <div className='container'>
            <h3 className='heading' ref={(h3) => (this.sectionHeading = h3)}>
              Insights.
            </h3>
            <div className='blogs'>
              {this.state.posts.map((post, index) => (
                <div
                  className='outer'
                  key={index}
                  ref={(div) => (this.instOuter[index] = div)}
                >
                  <div className='blog-img'>
                    <img src={post.image} alt={post.image} />
                  </div>
                  <div>
                    <a href={"/blog/" + post.slug}>
                      <span>{post.title}</span>
                    </a>
                    <h5>{post.date}</h5>
                  </div>
                </div>
              ))}
              {/* <div className="outer">
                <h5>12</h5>
                <a href="#!">
                  <span>hey</span>
                </a>
              </div> */}
            </div>
          </div>
        </section>
      </>
    );
  }
}
