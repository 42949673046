import React, { Component } from "react";

import Header from "./../sections/Header";
import Footer from "./../sections/Footer";
import Menu from "./../sections/Menu";
import Insights from "../sections/Insights";
import Talk from "../sections/Talk";
import { Helmet } from "react-helmet";

// import { gsap, TimelineMax } from "gsap/all";

class Work extends Component {
  componentDidMount() {}
  componentWillMount() {
    document.body.classList.add("work-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("work-page");
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Yati Labs Projects - Awesomeness Inside. Sneak peak into our past
            and upcoming Mobile App Projects.
          </title>
          <meta
            name='description'
            content='We work closely with our clients to define, design and develop transformative user experiences. We believe in what we do and it’s the quality, diligence and sincerity that we put into making a product that distinguishes us from the crowd.'
          />
          <meta
            property='og:description'
            content='We work closely with our clients to define, design and develop transformative user experiences. We believe in what we do and it’s the quality, diligence and sincerity that we put into making a product that distinguishes us from the crowd.'
          />
          <meta property='og:type' content='website' />
          <meta
            property='og:title'
            content='Yati Labs Projects - Awesomeness Inside. Sneak peak into our past
            and upcoming Mobile App Projects.'
          />
        </Helmet>
        <Header />

        {/* <Splash /> */}

        <Menu />

        <section className='page-heading'>
          <div className='bg'></div>
          <h1>Work</h1>
        </section>

        <section className='o-w l-b empty-mt'>
          <div className='container'>
            <div className='ct'>
              <h3 className='heading'>Our Work.</h3>
              <h2>
                We work closely with our clients to define, design and develop
                transformative user experiences. We believe in what we do and
                it’s the quality, diligence and sincerity that we put into
                making a product that distinguishes us from the crowd.
              </h2>
            </div>
          </div>
        </section>

        <section className='o-p l-b'>
          <div className='container'>
            <div className='p-c'>
              <div className='row one'>
                <div className='c-6'>
                  <div className='p-out one'>
                    <div className='gradt'>
                      <div className='txt'>
                        <h3>FineDine</h3>
                        <p>UI/UX, Mobile, Web</p>
                      </div>
                      <img
                        src='images/work/fine-dine.png'
                        alt='tenattag yatilabs'
                      />
                      <div className='links'>
                        <h3>FineDine</h3>
                        <p>UI/UX, Mobile, Web</p>
                        <p style={{ opacity: 0.4 }}>
                          Fine Dine app connects you with the best Bartenders,
                          Servers, Chefs, Food and Deals in your local area.
                          Favorite your server so you are notified when they
                          start their shift and get served with the best
                          experience. Tip them if you like their service from
                          within the application. With intuitive swipe feature,
                          easily browse the best of the best in the industry.
                        </p>
                        <div className='btn'>
                          <a
                            href='https://apps.apple.com/in/app/find-dine-app/id1438027707'
                            target='_target'
                          >
                            iOS
                          </a>
                          {/* <a href="#!">Android</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='c-6'>
                  <div className='p-out two'>
                    <div className='gradt'>
                      <div className='txt'>
                        <h3>OkMenu</h3>
                        <p>UI/UX, Mobile, Startup</p>
                      </div>
                      <img src='images/work/okmenu.png' alt='okmenu yatilabs' />
                      <div className='links'>
                        <h3>OkMenu</h3>
                        <p>UI/UX, Mobile, Startup</p>
                        <p style={{ opacity: 0.4 }}>
                          Menu in your palms. Top rated restaurant menu app
                          solution that helps to increase loyalty of your
                          restaurant, cafe guests and convert them into a loyal
                          clients. Easily digitise your menu along with pricing
                          of each item.
                        </p>
                        <div className='btn'>
                          <a
                            href='https://apps.apple.com/in/app/ok-menu-restaurants-menu-app/id1071380395'
                            target='_blank'
                          >
                            iOS
                          </a>
                          <a
                            href='https://play.google.com/store/apps/details?id=com.okdme.okmenue'
                            target='_blank'
                          >
                            Android
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row two'>
                <div className='c-6'>
                  <div className='p-out three'>
                    <div className='gradt'>
                      <div className='txt'>
                        <h3>Nightapp</h3>
                        <p>UI/UX, Mobile, Startup</p>
                      </div>
                      <img
                        src='images/work/nightapp.png'
                        alt='nightapp yatilabs'
                      />
                      <div className='links'>
                        <h3>Nightapp</h3>
                        <p>UI/UX, Mobile, Startup</p>
                        <p style={{ opacity: 0.4 }}>
                          Locate in live time the most busy parties near you by
                          using the heat map style map. Nightapp tells you how
                          busy bars, clubs and other party places are. Plan your
                          party in advance using the hourly wheel and track
                          which clubs are favoured by the crowd.
                        </p>
                        <div className='btn'>
                          {/* <a href="#!">iOS</a> */}
                          <a
                            href='https://play.google.com/store/apps/details?id=com.lemust'
                            target='_blank'
                          >
                            Android
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='c-6'>
                  <div className='p-out four'>
                    <div className='gradt'>
                      <div className='txt'>
                        <h3>Unni</h3>
                        <p>UI/UX, Mobile, Startup</p>
                      </div>
                      <img src='images/work/unni.png' alt='unni yatilabs' />
                      <div className='links'>
                        <h3>Unni</h3>
                        <p>UI/UX, Mobile, Startup</p>
                        <p style={{ opacity: 0.4 }}>
                          Meet Unni, the world’s newest messenger app that
                          translates text uniquely and fast according to who you
                          are connected to - from Atlanta to Bangkok and
                          everywhere in between - making each message more
                          meaningful. Connect with friends, family, and loved
                          ones in their own language.
                        </p>
                        {/* <div className="btn">
                          <a href="#!">iOS</a>
                          <a href="#!">Android</a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row three'>
                <div className='c-6'>
                  <div className='p-out five'>
                    <div className='gradt'>
                      <div className='txt'>
                        <h3>Zabing</h3>
                        <p>UI/UX, Startup</p>
                      </div>
                      <img
                        src='images/work/zabbing.png'
                        alt='txtreminders yatilabs'
                      />
                      <div className='links'>
                        <h3>Zabing</h3>
                        <p>UI/UX, Startup</p>
                        <p style={{ opacity: 0.4 }}>
                          Zabing was created with an idea that none of us should
                          save anything for the next life. And although many of
                          us understand this concept, life often gets in the way
                          and we default to living on autopilot. Zabing lets you
                          reconnect with yourself and with what matters.
                        </p>
                        <div className='btn'>
                          <a
                            href='https://apps.apple.com/us/app/zabing/id1451895881'
                            target='_blank'
                          >
                            iOS
                          </a>
                          <a
                            href='https://play.google.com/store/apps/details?id=com.app.zabing'
                            target='_blank'
                          >
                            Android
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='c-6'>
                  <div className='p-out six'>
                    <div className='gradt'>
                      <div className='txt'>
                        <h3>Credo Beauty</h3>
                        <p>UI/UX, Mobile, Web, Branding</p>
                      </div>
                      <img src='images/work/credo.png' alt='credo yatilabs' />
                      <div className='links'>
                        <h3>Credo Beauty</h3>
                        <p>UI/UX, Mobile, Web, Branding</p>
                        <p style={{ opacity: 0.4 }}>
                          Credo Beauty is one of the largest clean beauty
                          retailer on the planet with stores across the nation
                          and are partnered with 130+ brands to rethink what
                          goes into products, onto your skin, and down the
                          drain.
                        </p>
                        <div className='btn'>
                          <a href='http://www.credobeauty.com' target='_blank'>
                            Visit
                          </a>
                          {/* <a href="#!">Android</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row four'>
                <div className='c-6'>
                  <div className='p-out seven'>
                    <div className='gradt'>
                      <div className='txt'>
                        <h3>PocketSmith</h3>
                        <p>UI/UX, Startup</p>
                      </div>
                      <img
                        src='images/work/pocketsmith.png'
                        alt='pocketsmith yatilabs'
                      />
                      <div className='links'>
                        <h3>PocketSmith</h3>
                        <p>UI/UX, Startup</p>
                        <p style={{ opacity: 0.4 }}>
                          A time machine for your money. Pocketsmith not only
                          helps you to stay on top of your spending activity but
                          also projects your spending for the next 30 years.
                          With pocketsmith you can add notes to your spending,
                          view all your account balances at a glance and set
                          unlimited budgets.
                        </p>
                        <div className='btn'>
                          <a
                            href='https://apps.apple.com/us/app/pocketsmith/id1159515083'
                            target='_blank'
                          >
                            iOS
                          </a>
                          <a
                            href='https://play.google.com/store/apps/details?id=com.pocketsmith.app'
                            target='_blank'
                          >
                            Android
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='c-6'>
                  <div className='p-out eight'>
                    <div className='gradt'>
                      <div className='txt'>
                        <h3>No White Walls</h3>
                        <p>UI/UX, Startup</p>
                      </div>
                      <img
                        src='images/work/nowhitewalls.png'
                        alt='nowhitewalls yatilabs'
                      />
                      <div className='links'>
                        <h3>No White Walls</h3>
                        <p>UI/UX, Startup</p>
                        <p style={{ opacity: 0.4 }}>
                          Renting a property has never been easier. NoWhiteWalls
                          helps you find high quality properties where co-living
                          means more than just sharing a house. It means
                          somewhere you can be yourself, find lifelong friends
                          and really feel at home.
                          <br />
                          Find a stellar house you’d be proud to call home. From
                          flats to terraces, bungalows to townhouses, NWW, help
                          you avoid generic “blah” accommodation and move into a
                          space that feels right for you.
                        </p>
                        {/* <div className="btn">
                          <a href="#!">iOS</a>
                          <a href="#!">Android</a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Insights posts={3} />
        <Talk />
        <Footer />
      </>
    );
  }
}

export default Work;
