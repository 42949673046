import React, { Component } from "react";

export default class Splash extends Component {
  render() {
    return (
      <section className="splash d-b">
        <div className="bg"></div>
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="250"
            height="250"
            viewBox="0 0 150 170"
          >
            <path
              className="dot"
              fill="#fff"
              d="M135.13,127.06H151.2a0,0,0,0,1,0,0v16.07a0,0,0,0,1,0,0h0a16.07,16.07,0,0,1-16.07-16.07v0A0,0,0,0,1,135.13,127.06Z"
            />
            <path
              className="letter"
              fill="#fff"
              d="M102.68,68.4l36-68.4h20.66L111.78,85.65v57.48h0a18.19,18.19,0,0,1-18.19-18.2V85.65L46,0H66.86Z"
            />
            <polygon points="79.42 143.13 0 143.13 0 0 30.49 0 30.49 15.9 16 15.9 16 127.23 79.42 127.23 79.42 143.13" />
          </svg>
        </div>
      </section>
    );
  }
}
